<template lang="">
  <div style="margin-left: 10px; margin-right: 10px">
    <div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckChecked"
          style="padding: 10px"
          checked
          v-model="showCat"
        />
        <label
          class="form-check-label"
          for="flexCheckChecked"
          style="padding: 0px; padding-top: 2px"
        >
          Категории
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          style="padding: 10px"
          id="flexCheckChecked1"
          checked
          v-model="showCauses"
        />
        <label
          class="form-check-label"
          for="flexCheckChecked1"
          style="padding: 0px; padding-top: 2px"
        >
          Причины отсутствия
        </label>
      </div>
    </div>
    <button
      style="margin-top: 10px; margin-bottom: 10px"
      @click="init()"
      class="btn btn-outline-success"
      id="btnShowTable"
      title=" Данный отчет является аналогом Сводного отчета по диапазонам дат (Дети),
      но выгружается сразу в Excel файле. На много быстрее, чем основной. Не
      считает по категориям. Можно выбрать параметры перед выгрузкой"
    >
      Скачать глобальный отчет
    </button>
    <div v-if="showPreloader">
      <Preloader />
    </div>
  </div>
</template>
<script>
import Preloader from "./Preloader.vue";
import * as XLSX from "xlsx";
export default {
  components: {
    Preloader: Preloader,
  },
  props: ["classList", "marks", "studentsListProps", "selectedClassID"],
  data() {
    return {
      marksLocal: "",
      days: [],
      printTableHead: [],
      studentsList: [],
      showCat: true,
      showCauses: true,
      dateList: [],
      showPreloader: false,
      arr: [
        ["", "", "", "", ""],
        [
          "Класс",
          "Фамилия Имя",
          "Уровень образования",
          "Текущая категория",
          "Смена",
        ],
      ],
    };
  },
  methods: {
    init() {
      this.arr = [
        ["", "", "", "", ""],
        [
          "Класс",
          "Фамилия Имя",
          "Уровень образования",
          "Текущая категория",
          "Смена",
        ],
      ];
      this.studentsList = JSON.parse(JSON.stringify(this.studentsListProps));
      this.marksLocal = JSON.parse(JSON.stringify(this.marks));

      this.dateList = [];
      let tempFunction = this.showLoad();
      const p = new Promise((resolve) => {
        tempFunction;
        setTimeout(() => {
          resolve(1);
        }, 100);
      });

      p.then((data) => {
        if (data) {
          tempFunction = this.getClassName();
          p.then((data) => {
            if (data) {
              tempFunction = this.getHeaders();
              p.then(async (data) => {
                if (data) {
                  tempFunction = this.getBodyTable();
                  p.then((data) => {
                    if (data) {
                      tempFunction = this.insertMissingDays();
                      p.then((data) => {
                        if (data) {
                          tempFunction = this.pushHeaderToArray(this.arr);
                          p.then((data) => {
                            if (data) {
                              tempFunction = this.pushDataToArr(this.arr);
                              p.then((data) => {
                                if (data) {
                                  tempFunction = this.exportXLSX(this.arr);
                                  p.then((data) => {
                                    if (data) {
                                      this.showLoad();
                                    }
                                  });
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    // async init() {
    //   await this.getClassName();
    //   await this.getHeaders();

    //   await this.getBodyTable();
    //   await this.insertMissingDays();
    //   await this.pushHeaderToArray(this.arr);
    //   await this.pushDataToArr(this.arr);
    //   await this.exportXLSX(this.arr);

    //   await this.showLoad();

    //   // this.getClassName();
    //   //  this.generateFile(arr);
    // },

    showLoad() {
      this.showPreloader = !this.showPreloader;
    },

    pushHeaderToArray(arr) {
      for (let i = 0; i < this.days.length; i++) arr[1].push(this.days[i]);
      for (let i = 0; i < this.printTableHead.length; i++) {
        let flag = 0;
        for (let j = 0; j < this.printTableHead[i].day.length; j++) {
          if (!flag) {
            arr[0].push(this.printTableHead[i].month);
          } else {
            arr[0].push("/");
          }
          flag = 1;
        }
        flag = 0;
      }
    },
    pushDataToArr(arr) {
      for (let i = 0; i < this.studentsList.length; i++) {
        arr.push([
          this.studentsList[i].className,
          this.studentsList[i].FirstName + " " + this.studentsList[i].LastName,
          this.studentsList[i].level,
          this.studentsList[i].Category,
          this.studentsList[i].shift,
        ]);
        for (let j = 0; j < this.studentsList[i].marks.length; j++) {
          let text = "error";

          if (this.studentsList[i].marks[j].countEating) {
            if (this.studentsList[i].marks[j].causesID != "П") {
              this.studentsList[i].marks[j].countEating = 0;
            }

            if (this.showCat) {
              if (
                this.studentsList[i].marks[j].cat != undefined &&
                this.studentsList[i].marks[j].cat != "            " &&
                this.studentsList[i].marks[j].cat != ""
              ) {
                text =
                  this.studentsList[i].marks[j].countEating +
                  " (" +
                  this.studentsList[i].marks[j].cat +
                  ")";
              } else {
                text = this.studentsList[i].marks[j].countEating;
              }
            } else {
              text = this.studentsList[i].marks[j].countEating;
            }
          } else {
            if (!this.showCauses) {
              text = "";
            } else {
              text = this.studentsList[i].marks[j].causesID;
              if (this.studentsList[i].marks[j].causesID === undefined) {
                text = "n/a";
              }
            }
          }

          arr[arr.length - 1].push(text);
        }
      }
    },

    exportXLSX(ws_data) {
      console.log(ws_data);
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "SheetJS Tutorial",
        Subject: "Test",
        Author: "Red Stapler",
        CreatedDate: new Date(2017, 12, 19),
      };

      wb.SheetNames.push("Test Sheet");
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      wb.Sheets["Test Sheet"] = ws;

      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      let className = "";
      if (this.selectedClassID != "")
        className = this.selectedClassID.className;
      this.downloadBlob(
        new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
        className +
          " " +
          new Date(this.dateList[0]).toLocaleDateString() +
          "-" +
          new Date(
            this.dateList[this.dateList.length - 1]
          ).toLocaleDateString() +
          " ACT.xlsx"
      );
    },
    arrayToCsv(data) {
      return data
        .map(
          (row) =>
            row
              .map(String) // convert every value to String
              .map((v) => v.replaceAll('"', '""')) // escape double colons
              .map((v) => `"${v}"`) // quote it
              .join(",") // comma-separated
        )
        .join("\r\n"); // rows starting on new lines
    },
    downloadBlob(content, filename, contentType) {
      //   // Create a blob
      //   var blob = new Blob([content], { type: contentType });
      //   var url = URL.createObjectURL(blob);

      //   // Create a link to download it
      //   var pom = document.createElement("a");
      //   pom.href = url;
      //   pom.setAttribute("download", filename);
      //   pom.click();

      var blob = new Blob([content], { type: contentType });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    getClassName() {
      if (this.selectedClassID != "") {
        let filter = this.selectedClassID;
        this.studentsList = this.studentsList.filter(function (el) {
          if (el.classID == filter.classID) return el;
        });
      }
      for (let i = 0; i < this.studentsList.length; i++) {
        this.studentsList[i].className = "";
        for (let j = 0; j < this.classList.length; j++) {
          if (this.studentsList[i].classID === this.classList[j].classID) {
            this.studentsList[i].className = this.classList[j].className;
            this.studentsList[i].shift = this.classList[j].shift;
            if (this.classList[j].level === 3) {
              this.studentsList[i].level = "Среднее";
            }
            if (this.classList[j].level === 2) {
              this.studentsList[i].level = "Основное";
            }
            if (this.classList[j].level === 1) {
              this.studentsList[i].level = "Начальное";
            }
          }
        }
      }
    },
    getHeaders() {
      this.printTableHead = [];
      this.days = [];
      let dateListTemp = [];
      this.dateList = [];
      for (let i = this.marksLocal.length; i--; ) {
        dateListTemp.push(
          this.marksLocal[i].date[3] +
            this.marksLocal[i].date[4] +
            this.marksLocal[i].date[2] +
            this.marksLocal[i].date[0] +
            this.marksLocal[i].date[1] +
            this.marksLocal[i].date[5] +
            this.marksLocal[i].date[6] +
            this.marksLocal[i].date[7] +
            this.marksLocal[i].date[8] +
            this.marksLocal[i].date[9]
        );
        this.marksLocal[i].causesID = this.changeMarkCausesName(
          this.marksLocal[i]
        ); //попутно меняем названия для причин в марке
      }

      dateListTemp = [...new Set(dateListTemp)];
      for (let i = 0; i < dateListTemp.length; i++) {
        let month = Number(dateListTemp[i][0] + dateListTemp[i][1]);
        let day = Number(dateListTemp[i][3] + dateListTemp[i][4]);
        let year = Number(
          dateListTemp[i][6] +
            dateListTemp[i][7] +
            dateListTemp[i][8] +
            dateListTemp[i][9]
        );
        dateListTemp[i] = new Date(year, month - 1, day);
      }
      this.dateList = dateListTemp;

      dateListTemp.sort((a, b) => (a > b ? 1 : -1));
      this.dateList.sort((a, b) => (a > b ? 1 : -1));
      let month = [];
      for (let i in dateListTemp) {
        this.days.push(dateListTemp[i].getDate());

        month.push(dateListTemp[i].getMonth());
      }
      month = [...new Set(month)];
      for (let i = 0; i < month.length; i++) {
        this.printTableHead.push({
          monthNumber: month[i],
          month: this.getNameMonth(month[i]),
          day: [],
        });
        for (let j = 0; j < dateListTemp.length; j++) {
          if (
            this.printTableHead[i].monthNumber == dateListTemp[j].getMonth()
          ) {
            this.printTableHead[i].day.push(dateListTemp[j].getDate());
          }
        }
      }

      this.dateList = dateListTemp;
      dateListTemp = "";
    },
    getBodyTable() {
      function groupBy(key) {
        return function group(array) {
          return array.reduce((acc, obj) => {
            const property = obj[key];
            acc[property] = acc[property] || [];
            acc[property].push(obj);
            return acc;
          }, {});
        };
      }

      const groupBystudentID = groupBy("studentID");

      this.marksLocal = groupBystudentID(this.marksLocal);

      // let datelisttemp = this.dateList;
      for (let i = this.studentsList.length; i--; ) {
        this.studentsList[i].studentID =
          this.studentsList[i].FirstName + " " + this.studentsList[i].LastName;

        this.studentsList[i].marks = [];

        for (let key in this.marksLocal) {
          if (this.studentsList[i]._id === key) {
            this.studentsList[i].marks = JSON.parse(
              JSON.stringify(this.marksLocal[key])
            );
          }
        }
        delete this.marksLocal[this.studentsList[i]._id];
      }
      if (Object.keys(this.marksLocal).length != 0) {
        for (let key in this.marksLocal) {
          this.studentsList.push({
            studentID: this.marksLocal[key][0].studentID,
            className: this.marksLocal[key][0].className,
            marks: this.marksLocal[key],
          });
        }
      }
    },

    insertMissingDays() {
      for (let i = this.studentsList.length; i--; ) {
        if (this.studentsList[i].marks.length != this.dateList.length) {
          let dateTemp = JSON.parse(JSON.stringify(this.dateList));

          for (let j = 0; j < this.studentsList[i].marks.length; j++) {
            let date = new Date(
              Number(
                this.studentsList[i].marks[j].date[6] +
                  this.studentsList[i].marks[j].date[7] +
                  this.studentsList[i].marks[j].date[8] +
                  this.studentsList[i].marks[j].date[9]
              ),
              Number(
                this.studentsList[i].marks[j].date[3] +
                  this.studentsList[i].marks[j].date[4]
              ) - 1,
              Number(
                this.studentsList[i].marks[j].date[0] +
                  this.studentsList[i].marks[j].date[1]
              )
            );
            for (let d = 0; d < dateTemp.length; d++) {
              if (
                new Date(dateTemp[d]).toLocaleDateString() ===
                date.toLocaleDateString()
              ) {
                dateTemp[d] = this.studentsList[i].marks[j];
                break;
              }
            }
          }

          // for (let o = 0; o < dateTemp.length; o++) {
          //   if (dateTemp[o] === null) {
          //     dateTemp[o] = "";
          //   }
          // }
          this.studentsList[i].marks = dateTemp;
        }
      }
    },
    changeMarkCausesName(mark) {
      let tempCausesMark = "";
      tempCausesMark = mark.causesID;
      if (mark.causesID == "По болезни") {
        tempCausesMark = "Б";
      }
      if (mark.causesID == "Погодные условия") {
        tempCausesMark = "ПУ";
      }
      if (mark.causesID == "По болезни (Простудные заболевания)") {
        tempCausesMark = "Б(п)";
      }
      if (mark.causesID == "Заявление") {
        tempCausesMark = "Заяв.";
      }
      if (mark.causesID == "Питался") {
        tempCausesMark = "П";
      }
      if (mark.causesID == "Мероприятие") {
        tempCausesMark = "М";
      }
      if (mark.causesID == "Санаторий") {
        tempCausesMark = "С";
      }
      if (mark.causesID == "Домашнее обучение") {
        tempCausesMark = "Д";
      }
      if (mark.causesID == "Неуважительно") {
        tempCausesMark = "Н";
      }
      return tempCausesMark;
    },
    getNameMonth(month) {
      let tempMonth = "";
      if (month == 0) {
        tempMonth = "Январь";
      }
      if (month == 1) {
        tempMonth = "Февраль";
      }
      if (month == 2) {
        tempMonth = "Март";
      }
      if (month == 3) {
        tempMonth = "Апрель";
      }
      if (month == 4) {
        tempMonth = "Май";
      }
      if (month == 5) {
        tempMonth = "Июнь";
      }
      if (month == 6) {
        tempMonth = "Июль";
      }
      if (month == 7) {
        tempMonth = "Август";
      }
      if (month == 8) {
        tempMonth = "Сентябрь";
      }
      if (month == 9) {
        tempMonth = "Октябрь";
      }
      if (month == 10) {
        tempMonth = "Ноябрь";
      }
      if (month == 11) {
        tempMonth = "Декабрь";
      }
      return tempMonth;
    },
    // //csv
    // generateFile(arr) {
    //   let csv = this.arrayToCsv(arr);
    //   var uint8 = new Uint8Array(csv.length);
    //   for (var i = 0; i < csv.length; i++) {
    //     let x = csv.charCodeAt(i);
    //     if (x >= 1040 && x <= 1103) {
    //       // Символы А..Я а..я
    //       x -= 848;
    //     } else if (x == 1025) {
    //       // Символ Ё
    //       x = 168;
    //     } else if (x == 1105) {
    //       // Символ ё
    //       x = 184;
    //     }
    //     uint8[i] = x;
    //   }
    //   this.downloadBlob(uint8, "export.csv", "text/csv;charset=UTF-8;");
    // },
  },
};
</script>
<style lang=""></style>
