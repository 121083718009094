<template>
  <div
    style="
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100px;
      background-color: transparent;
    "
  >
    <div>Загрузка...</div>
    <br />
    <div class="spinner-border" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
